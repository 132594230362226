import EN from "./images/EN.png";
import DE from "./images/DE.png";
import ES from "./images/ES.png";
import FR from "./images/FR.png";
import IT from "./images/IT.png";
import NL from "./images/NL.png";
import PL from "./images/PL.png";
import BE from "./images/BE.png";
import US from "./images/US.png";
import CZ from "./images/CZ.png";
import AT from "./images/AT.png";
import CH from "./images/CH.png";
import AR from "./images/AR.png";
import BZ from "./images/BZ.png";
import FI from "./images/FI.png";
import LU from "./images/LU.png";
import RO from "./images/RO.png";
import SC from "./images/SC.png";

export const countryIcon = (country) => {
  switch (country?.toUpperCase()) {
    case "EN":
      return EN;
    case "GB":
      return EN;
    case "DE":
      return DE;
    case "ES":
      return ES;
    case "FR":
      return FR;
    case "IT":
      return IT;
    case "NL":
      return NL;
    case "PL":
      return PL;
    case "BE":
      return BE;
    case "US":
      return US;
    case "CZ":
      return CZ;
    case "AT":
      return AT;
    case "CH":
      return CH;
    case "AR":
      return AR;
    case "BZ":
      return BZ;
    case "FI":
      return FI;
    case "LU":
      return LU;
    case "RO":
      return RO;
    case "SC":
      return SC;
    default:
      return null;
  }
};
