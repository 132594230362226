import * as t from "./actionTypes";
import * as a from "../app/actionTypes";
import Auth from "../core/Auth";

const initialState = {
  logged: Auth.isLoggedIn(),
  changeCountry: { loader: true },
  countries: [],
  prevPath: "",
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.AUTH_SUCCESS:
    case t.SYNC_AUTH_USER_LOGIN:
      return Object.assign({}, state, {
        logged: true,
      });
    case t.AUTH_LOGOUT:
    case t.AUTH_FAILURE:
    case t.SYNC_AUTH_USER_LOGOUT:
      return Object.assign({}, state, {
        logged: false,
      });

    case t.FETCH_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        countries: action.data.countries || [],
      });

    case t.COUNTRY_SWITCHED:
      return Object.assign({}, state, {
        changeCountry: { loader: true },
      });

    case a.FETCH_TRANSLATION_SUCCESS:
      return Object.assign({}, state, {
        changeCountry: { loader: false },
      });

    case a.FETCH_TRANSLATION_FAILURE:
      return Object.assign({}, state, {
        changeCountry: { loader: false },
      });
    case t.SET_PREV_PATH:
      return Object.assign({}, state, {
        prevPath: action.payload,
      });

    default:
      return state;
  }
};

export default loginReducer;
