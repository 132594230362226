import React from "react";
import { useSelector } from "react-redux";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { Route, Redirect } from "react-router-dom";
import {
  Shop,
  ShopDesktop,
  ProductDetails,
  ProductDetailsDesktop,
} from "./modules/shop";
import { SET_PREV_PATH } from "./modules/login/actionTypes";
import { Home, HomeDesktop } from "./modules/home";
import { useCountry } from "./hooks/useCountry";
import { SearchDesktop, SearchMobile } from "./modules/search";

export const history = createBrowserHistory();

export const middleware = routerMiddleware(history);

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { logged } = useSelector((state) => state.login);

  return (
    <Route
      {...rest}
      render={(props) =>
        logged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export const ValidateSupportedCountryRoute = ({
  component: Component,
  ...rest
}) => {
  const { isCountryValid } = useCountry();

  return (
    <Route
      {...rest}
      render={(props) => {
        return isCountryValid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/country",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export const NotSupportedCountryRoute = ({ component: Component, ...rest }) => {
  const { isCountryValid } = useCountry();

  return (
    <Route
      {...rest}
      render={(props) => {
        return isCountryValid ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export const trackPreviousPathMiddleware = (store) => (next) => (action) => {
  if (action.type === "@@router/LOCATION_CHANGE") {
    const state = store.getState();
    const currentPath = state.router.location.pathname;
    if (currentPath !== "/login") {
      store.dispatch({ type: SET_PREV_PATH, payload: currentPath });
    }
  }
  return next(action);
};

export const responsiveMatrix = {
  orders: {
    mobile: Home,
    tablet: HomeDesktop,
    desktop: HomeDesktop,
  },
  shop: {
    mobile: Shop,
    tablet: ShopDesktop,
    desktop: ShopDesktop,
  },
  product: {
    mobile: ProductDetails,
    tablet: ProductDetailsDesktop,
    desktop: ProductDetailsDesktop,
  },
  search: {
    mobile: SearchMobile,
    tablet: SearchDesktop,
    desktop: SearchDesktop,
  },
};
