import { useEffect, useState } from "react";
import { useDevice } from "../../../../../hooks/useDevice";
import { useSelector } from "react-redux";
import { getSelectedHeaderByDevice } from "../../../../menu/selectors";

const calculateHeaderHeight = (isMobile, header, isHeader, hasCustomHeader) => {
  if (!isMobile) {
    return "0px";
  }
  if (header && (isHeader || hasCustomHeader)) {
    return "128px";
  }
  if (isHeader || hasCustomHeader) {
    return "64px";
  }
  return "0px";
};

export const useHeaderHeight = (hasCustomHeader) => {
  const { device } = useDevice();
  const { location } = useSelector((state) => state.router);
  const isMobile = device === "mobile";
  const header = useSelector((state) => state.header?.countryOptions?.header);
  const isHeader = getSelectedHeaderByDevice(device);
  const [headerHeight, setHeaderHeight] = useState("");

  useEffect(() => {
    const height = calculateHeaderHeight(
      isMobile,
      header,
      isHeader,
      hasCustomHeader
    );
    setHeaderHeight(height);
  }, [location, isMobile, header, isHeader]);

  return headerHeight;
};
