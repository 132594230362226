import DeviceSelector from "../core/DeviceSelector";

export const NAME = "translations";

export const translationsCodes = [
  "eo.home",
  "eo.shop",
  "eo.contact",
  "eo.cart",
  "eo.cart-full",
  "eo.email",
  "eo.password",
  "eo.enter",
  "eo.myorders",
  "eo.myproducts",
  "eo.othervolumes",
  "eo.reorder",
  "eo.placed",
  "eo.sent",
  "eo.in-progress",
  "eo.add",
  "eo.profile",
  "eo.logout",
  "eo.visit-request",
  "eo.send-email",
  "eo.make-call",
  "eo.whatsapp-contact",
  "eo.support",
  "eo.your-ta",
  "eo.contact-me",
  "eo.vat-number",
  "eo.vat-invalid-characters",
  "eo.contact-us",
  "eo.your-first-name",
  "eo.your-last-name",
  "eo.your-name",
  "eo.request",
  "eo.your-contact-phone",
  "eo.send-request",
  "eo.required",
  "eo.invalid-phone-number",
  "eo.contact-default-message",
  "eo.contact-default-prod-name",
  "eo.cart-empty",
  "eo.empty-cart-previous-orders",
  "eo.empty-cart-explore-shop",
  "eo.back-to-cart",
  "eo.items",
  "eo.packs",
  "eo.subtotal",
  "eo.net-price",
  "eo.total-before-vat",
  "eo.delivery-costs",
  "eo.subvat",
  "eo.gross",
  "eo.delivery-net",
  "eo.delivery-free",
  "eo.delivery-without-vat",
  "eo.cart-delivery-terms-title",
  "eo.cart-delivery-terms-body",
  "eo.categories",
  "eo.subcat-products",
  "eo.tag.aerosol",
  "eo.tag.drives",
  "eo.tag.dry-lube",
  "eo.tag.extreme-pressure",
  "eo.tag.food-grade",
  "eo.tag.heat-resistant",
  "eo.tag.hydraulic",
  "eo.tag.low-temperature",
  "eo.tag.multipurpose",
  "eo.tag.pneumatic",
  "eo.tag.water-resistant",
  "eo.tag.halal",
  "eo.tag.kosher",
  "eo.tag.vegan",
  "eo.tag.high-speed",
  "eo.tag.bearings",
  "eo.tag.chains",
  "eo.tag.gears",
  "eo.tag.additives",
  "eo.tag.gearboxes",
  "eo.tag.biodegradable",
  "eo.tag.pfas-free",
  "eo.tag.wire-ropes",
  "eo.tds",
  "eo.sds",
  "eo.nsf",
  "eo.read-more",
  "eo.pack",
  "eo.presentation-request",
  "eo.presentation-requested",
  "eo.volume",
  "eo.invoice-data",
  "eo.delivery-address",
  "eo.add-new-delivery-address",
  "eo.invoice-not-valid-help-msg",
  "eo.cancel-order",
  "eo.submit-order",
  "eo.new-order-registered",
  "eo.my-profile",
  "eo.filters",
  "eo.search",
  "eo.search-results",
  "eo.search-no-results",
  "eo.search-too-short-query",
  "eo.show-orders",
  "eo.when",
  "eo.apply-filters",
  "eo.per",
  "eo.product-unavailable",
  "eo.contains-product",
  "eo.filter-when6",
  "eo.filter-when12",
  "eo.filter-whenAll",
  "eo.by-who",
  "eo.order",
  "eo.explore-store",
  "eo.request-contact",
  "eo.help",
  "eo.sign",
  "eo.back-to-shop",
  "eo.cost-of",
  "eo.log-in",
  "eo.enable-prices",
  "eo.request-a-call-back",
  "eo.or",
  "eo.request-thank-you",
  "eo.request-will-call",
  "eo.question-already-registered",
  "eo.error-sorry",
  "eo.send-your-order",
  "eo.order-will-be-confirmed",
  "eo.expected-delivery",
  "eo.invoice-payment",
  "eo.what-next",
  "eo.invalid-email",
  "eo.type-email",
  "eo.your-email",
  "eo.step-more",
  "eo.check-emailbox",
  "eo.ok",
  "eo.sign-ok",
  "eo.enable-ordering",
  "eo.check-orders",
  "eo.contact-ta",
  "eo.root",
  "eo.orders-no-matching-orders",
  "eo.orders-no-history",
  "eo.products-no-history",
  "eo.ta-contact-before-delivery",
  "eo.disclaimer",
  "eo.privacy",
  "eo.terms",
  "eo.sitemap",
  "eo.register",
  "eo.passwords-not-identical",
  "eo.new-password",
  "eo.retype-password",
  "eo.type-new-password",
  "eo.current-password",
  "eo.password-match-old",
  "eo.password-must",
  "eo.rule-pw-length",
  "eo.rule-pw-lc-letter",
  "eo.rule-pw-uc-letter",
  "eo.rule-pw-number",
  "eo.rule-pw-special",
  "eo.activate-account-title",
  "eo.activation-token-invalid",
  "eo.register-again",
  "eo.new-password-invalid",
  "eo.msds-title",
  "eo.solution-title",
  "eo.choose",
  "eo.change-delivery-address",
  "eo.new-delivery-address-in",
  "eo.error-invalid-postcode",
  "eo.company-name",
  "eo.recipient",
  "eo.address",
  "eo.city",
  "eo.zipcode",
  "eo.use-address",
  "eo.password-set",
  "eo.your-country",
  "eo.profile-settings",
  "eo.your-initial-lang",
  "eo.technical-problem-sign-in",
  "eo.back",
  "eo.change-interface-lang",
  "eo.hideothervolumes",
  "eo.adjust-invoice-address",
  "eo.change-invoice-address",
  "eo.def-new-delivery-address",
  "eo.new-delivery-address",
  "eo.new-order",
  "eo.your-phone",
  "eo.type-more-info",
  "eo.more-info-title",
  "eo.error",
  "eo.change-country",
  "eo.change-language",
  "eo.apply",
  "eo.unit-price",
  "eo.not-supported-country",
  "eo.select-country-on-top",
  "eo.cart-popover-message",
  "eo.go-to-cart",
  "eo.added",
  "eo.discount-info",
  "eo.phone-number",
  "eo.password-too-long",
  "eo.first-time-title",
  "eo.first-time-content",
  "eo.close",
  "eo.request-training",
  "eo.profile-initiate",
  "eo.may-also-need",
  "eo.recommendations",
  "eo.order-tnc",
  "eo.order-tnc-accept",
  "eo.order-tnc-terms",
  "eo.order-number",
  "eo.function-title",
  "eo.department",
  "eo.agreement-to-contact",
  "eo.privacy-statement",
  "eo.privacy-statement-url",
  "eo.yes-please",
  "eo.no-thank-you",
  "eo.forgot-password",
  "eo.sign-on",
  "eo.captcha",
  "eo.request-sending",
  "eo.request-sent",
  "eo.request-failed",
  "eo.currently-selected",
  "eo.more-info-added",
  "eo.no-products",
  "eo.forgot-password-title",
  "eo.forgot-password-info",
  "eo.forgot-password-ok",
  "eo.next-step",
  "eo.create-account",
  "eo.country-dear-visitor",
  "eo.country-webshop-not-avail",
  "eo.country-company-registered",
  "eo.country-for-more-info",
  "eo.country-our-website",
  "eo.country-website",
  "eo.terms-and-conditions",
  "eo.legal",
  "eo.legal-disclosure",
  "eo.cookies",
  "ict.country_cz",
  "ict.country_de",
  "ict.country_es",
  "ict.country_fr",
  "ict.country_gb",
  "ict.country_it",
  "ict.country_nl",
  "ict.country_pl",
  "ict.country_us",
  "ict.country_at",
  "ict.country_ch",
  "ict.country_ar",
  "ict.country_bz",
  "ict.country_fi",
  "ict.country_lu",
  "ict.country_ro",
  "ict.country_sc",
  "ict.country_be",
  "I8",
  "I9",
  "J0",
  "J1",
  "J2",
  "J3",
  "J4",
  "J5",
  "J6",
  "J7",
  "J8",
  "J9",
  "J10",
  "K0",
  "K2",
  "K3",
  "K4",
  "L9",
  "OI",
  "999",
  "108",
  "L8",
  "B41",
  "SU",
  "T0",
  "T2",
  "T3",
  "T7",
  "TC",
  "TF",
  "TQ",
];

export const initialState = {
  deviceType: DeviceSelector.currentDevice(),
  intl: {
    locale: "en",
    messages: {
      "eo.home": "",
      "eo.shop": "",
      "eo.contact": "",
      "eo.cart": "",
      "eo.cart-full": "",
      "eo.email": "",
      "eo.password": "",
      "eo.enter": "",
      "eo.myorders": "",
      "eo.myproducts": "",
      "eo.othervolumes": "",
      "eo.reorder": "",
      "eo.placed": "",
      "eo.sent": "",
      "eo.in-progress": "",
      "eo.add": "",
      "eo.profile": "",
      "eo.logout": "",
      "eo.visit-request": "",
      "eo.send-email": "",
      "eo.make-call": "",
      "eo.whatsapp-contact": "",
      "eo.support": "",
      "eo.your-ta": "",
      "eo.contact-me": "",
      "eo.vat-number": "",
      "eo.vat-invalid-characters": "",
      "eo.contact-us": "",
      "eo.request": "",
      "eo.your-first-name": "",
      "eo.your-last-name": "",
      "eo.your-name": "",
      "eo.your-contact-phone": "",
      "eo.send-request": "",
      "eo.required": "",
      "eo.invalid-phone-number": "",
      "eo.contact-default-message": "",
      "eo.contact-default-prod-name": "",
      "eo.cart-empty": "",
      "eo.empty-cart-previous-orders": "",
      "eo.empty-cart-explore-shop": "",
      "eo.back-to-cart": "",
      "eo.items": "",
      "eo.packs": "",
      "eo.subtotal": "",
      "eo.subvat": "",
      "eo.gross": "",
      "eo.delivery-net": "",
      "eo.delivery-free": "",
      "eo.delivery-without-vat": "",
      "eo.cart-delivery-terms-title": "",
      "eo.cart-delivery-terms-body": "",
      "eo.categories": "",
      "eo.subcat-products": "",
      "eo.tag.aerosol": "",
      "eo.tag.drives": "",
      "eo.tag.dry-lube": "",
      "eo.tag.extreme-pressure": "",
      "eo.tag.food-grade": "",
      "eo.tag.heat-resistant": "",
      "eo.tag.hydraulic": "",
      "eo.tag.low-temperature": "",
      "eo.tag.multipurpose": "",
      "eo.tag.pneumatic": "",
      "eo.tag.water-resistant": "",
      "eo.tag.halal": "",
      "eo.tag.kosher": "",
      "eo.tag.vegan": "",
      "eo.tag.high-speed": "",
      "eo.tag.bearings": "",
      "eo.tag.chains": "",
      "eo.tag.gears": "",
      "eo.tag.additives": "",
      "eo.tag.gearboxes": "",
      "eo.tag.biodegradable": "",
      "eo.tag.pfas-free": "",
      "eo.tag.wire-ropes": "",
      "eo.tds": "",
      "eo.msds": "",
      "eo.nsf": "",
      "eo.read-more": "",
      "eo.pack": "",
      "eo.presentation-request": "",
      "eo.presentation-requested": "",
      "eo.volume": "",
      "eo.invoice-data": "",
      "eo.delivery-address": "",
      "eo.add-new-delivery-address": "",
      "eo.invoice-not-valid-help-msg": "",
      "eo.cancel-order": "",
      "eo.submit-order": "",
      "eo.new-order-registered": "",
      "eo.my-profile": "",
      "eo.filters": "",
      "eo.search": "",
      "eo.search-results": "",
      "eo.search-no-results": "",
      "eo.search-too-short-query": "",
      "eo.show-orders": "",
      "eo.when": "",
      "eo.apply-filters": "",
      "eo.per": "",
      "eo.product-unavailable": "",
      "eo.contains-product": "",
      "eo.filter-when6": "",
      "eo.filter-when12": "",
      "eo.filter-whenAll": "",
      "eo.request-a-call-back": "",
      "eo.or": "",
      "eo.sign": "",
      "eo.log-in": "",
      "eo.by-who": "",
      "eo.order": "",
      "eo.explore-store": "",
      "eo.request-contact": "",
      "eo.help": "",
      "eo.back-to-shop": "",
      "eo.cost-of": "",
      "eo.request-thank-you": "",
      "eo.request-will-call": "",
      "eo.question-already-registered": "",
      "eo.error-sorry": "",
      "eo.send-your-order": "",
      "eo.order-will-be-confirmed": "",
      "eo.expected-delivery": "",
      "eo.invoice-payment": "",
      "eo.what-next": "",
      "eo.invalid-email": "",
      "eo.type-email": "",
      "eo.your-email": "",
      "eo.step-more": "",
      "eo.check-emailbox": "",
      "eo.ok": "",
      "eo.sign-ok": "",
      "eo.enable-prices": "",
      "eo.enable-ordering": "",
      "eo.check-orders": "",
      "eo.contact-ta": "",
      "eo.root": "",
      "eo.orders-no-matching-orders": "",
      "eo.orders-no-history": "",
      "eo.products-no-history": "",
      "eo.ta-contact-before-delivery": "",
      "eo.disclaimer": "",
      "eo.privacy": "",
      "eo.terms": "",
      "eo.sitemap": "",
      "eo.register": "",
      "eo.passwords-not-identical": "",
      "eo.new-password": "",
      "eo.retype-password": "",
      "eo.type-new-password": "",
      "eo.current-password": "",
      "eo.password-match-old": "",
      "eo.password-must": "",
      "eo.rule-pw-length": "",
      "eo.rule-pw-lc-letter": "",
      "eo.rule-pw-uc-letter": "",
      "eo.rule-pw-number": "",
      "eo.rule-pw-special": "",
      "eo.activate-account-title": "",
      "eo.activation-token-invalid": "",
      "eo.register-again": "",
      "eo.new-password-invalid": "",
      "eo.msds-title": "",
      "eo.solution-title": "",
      "eo.choose": "",
      "eo.change-delivery-address": "",
      "eo.new-delivery-address-in": "",
      "eo.error-invalid-postcode": "",
      "eo.company-name": "",
      "eo.recipient": "",
      "eo.address": "",
      "eo.city": "",
      "eo.zipcode": "",
      "eo.use-address": "",
      "eo.password-set": "",
      "eo.your-country": "",
      "eo.profile-settings": "",
      "eo.your-initial-lang": "",
      "eo.technical-problem-sign-in": "",
      "eo.back": "",
      "eo.change-interface-lang": "",
      "eo.hideothervolumes": "",
      "eo.adjust-invoice-address": "",
      "eo.change-invoice-address": "",
      "eo.def-new-delivery-address": "",
      "eo.new-delivery-address": "",
      "eo.new-order": "",
      "eo.your-phone": "",
      "eo.type-more-info": "",
      "eo.more-info-title": "",
      "eo.error": "",
      "eo.change-country": "",
      "eo.change-language": "",
      "eo.apply": "",
      "eo.unit-price": "",
      "eo.not-supported-country": "",
      "eo.select-country-on-top": "",
      "eo.cart-popover-message": "",
      "eo.go-to-cart": "",
      "eo.added": "",
      "eo.discount-info": "",
      "eo.phone-number": "",
      "eo.password-too-long": "",
      "eo.first-time-title": "",
      "eo.first-time-content": "",
      "eo.close": "",
      "eo.request-training": "",
      "eo.profile-initiate": "",
      "eo.may-also-need": "",
      "eo.recommendations": "",
      "eo.order-tnc": "",
      "eo.order-tnc-accept": "",
      "eo.order-tnc-terms": "",
      "eo.order-number": "",
      "eo.function-title": "",
      "eo.department": "",
      "eo.agreement-to-contact": "",
      "eo.privacy-statement": "",
      "eo.privacy-statement-url": "",
      "eo.yes-please": "",
      "eo.no-thank-you": "",
      "eo.forgot-password": "",
      "eo.sign-on": "",
      "eo.captcha": "",
      "eo.request-sending": "",
      "eo.request-sent": "",
      "eo.request-failed": "",
      "eo.currently-selected": "",
      "eo.more-info-added": "",
      "eo.no-products": "",
      "eo.forgot-password-title": "",
      "eo.forgot-password-info": "",
      "eo.forgot-password-ok": "",
      "eo.next-step": "",
      "eo.create-account": "",
      "eo.country-dear-visitor": "",
      "eo.country-webshop-not-avail": "",
      "eo.country-company-registered": "",
      "eo.country-for-more-info": "",
      "eo.country-our-website": "",
      "eo.country-website": "",
      "eo.terms-and-conditions": "",
      "eo.legal": "",
      "eo.legal-disclosure": "",
      "eo.cookies": "",
      "ict.country_cz": "",
      "ict.country_de": "",
      "ict.country_es": "",
      "ict.country_fr": "",
      "ict.country_gb": "",
      "ict.country_it": "",
      "ict.country_nl": "",
      "ict.country_pl": "",
      "ict.country_us": "",
      "ict.country_at": "",
      "ict.country_ch": "",
      "ict.country_ar": "",
      "ict.country_bz": "",
      "ict.country_fi": "",
      "ict.country_lu": "",
      "ict.country_ro": "",
      "ict.country_sc": "",
      "ict.country_be": "",
      I8: "",
      I9: "",
      J0: "",
      J1: "",
      J2: "",
      J3: "",
      J4: "",
      J5: "",
      J6: "",
      J7: "",
      J8: "",
      J9: "",
      J10: "",
      K0: "",
      K2: "",
      K3: "",
      K4: "",
      L9: "",
      OI: "",
      999: "",
      108: "",
      L8: "",
      B41: "",
      SU: "",
      T0: "",
      T2: "",
      T3: "",
      T7: "",
      TC: "",
      TF: "",
      TQ: "",
    },
  },
};
